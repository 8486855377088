import React from 'react';
import { css } from '@emotion/react';

const ZIGZAG_TEXT_LOGO_IMAGE_URL = 'https://cf.fe.s.zigzag.kr/common/logo/zigzag_text_logo.png';

export const ZigzagLogo = () => {
  return (
    <img
      css={css`
        width: auto;
        height: 22px;
      `}
      src={ZIGZAG_TEXT_LOGO_IMAGE_URL}
      alt='지그재그 로고'
    />
  );
};
