import { MainBanner } from '../components/MainBanner';
import { useMainBannerService } from '../services/useMainBannerService';

export const MainBannerContainer = () => {
  const { bannerList, handleBannerItemClick, handleBannerItemIntersect } = useMainBannerService();

  if (!bannerList || !bannerList.length) {
    return <div style={{ margin: '0 16px', paddingBottom: 'calc(100% - 32px)' }} />;
  }

  return (
    <MainBanner
      bannerList={bannerList}
      onItemClick={handleBannerItemClick}
      onItemIntersect={handleBannerItemIntersect}
    />
  );
};
