import { css } from '@emotion/react';

import { web_max_width } from '@common/styles';
import { ZigzagTopNavigation, ZigzagTopNavigationItemIcon } from '@common/top-navigation';

import { useHomeHeaderUBL } from '../log/useHomeHeaderUBL';

const stickyCss = css`
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
`;

const wrapperCss = css`
  max-width: ${web_max_width}px;
  margin: auto;
`;

export function HeaderContainer() {
  const { clickHeaderContentsUBL } = useHomeHeaderUBL();

  const handleLogoClick = () => clickHeaderContentsUBL('zigzag_home');
  const handleCartClick = () => clickHeaderContentsUBL('cart');
  const handleSearchClick = () => clickHeaderContentsUBL('search');

  return (
    <div css={stickyCss}>
      <div css={wrapperCss}>
        <ZigzagTopNavigation.Home onLogoClick={handleLogoClick}>
          <ZigzagTopNavigationItemIcon.SearchIcon onClick={handleSearchClick} />
          <ZigzagTopNavigationItemIcon.CartIcon onClick={handleCartClick} />
        </ZigzagTopNavigation.Home>
      </div>
    </div>
  );
}
