import React from 'react';
import { vars } from '@croquiscom-pvt/zds/tokens';
import { css } from '@emotion/react';
import clsx from 'clsx';

import type { ZDSComponentCommonProps } from '../utils/types';

const rootCss = css`
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${vars.color.backgroundSurface};
  align-items: center;

  &.small {
    height: 44px;
  }
  &.medium {
    height: 52px;
  }
`;

const groupCss = css`
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;
`;

export interface BaseTopNavigationRootProps extends ZDSComponentCommonProps {
  /**
   * @default medium
   * @description 상단 네비게이션의 높이를 설정합니다. (small: 44px, medium: 52px)
   */
  size?: 'small' | 'medium';
  children?: React.ReactNode;
}

export const BaseTopNavigationRoot = React.forwardRef<HTMLElement, BaseTopNavigationRootProps>(
  function BaseTopNavigationRoot(props, ref) {
    const { size = 'medium', className, ...restProps } = props;
    return (
      <nav
        css={rootCss}
        className={clsx([size, className])}
        ref={ref}
        aria-label='상단 네비게이션 메뉴'
        {...restProps}
      />
    );
  },
);

interface BaseTopNavigationGroupProps extends ZDSComponentCommonProps {
  children?: React.ReactNode | undefined;
}

export const BaseTopNavigationGroup = React.forwardRef(function BaseTopNavigationGroup(
  props: BaseTopNavigationGroupProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { children, ...restProps } = props;
  return (
    <div css={groupCss} ref={ref} {...restProps}>
      {children}
    </div>
  );
});

export interface BaseTopNavigationItemIconProps extends ZDSComponentCommonProps {
  children: React.ReactNode;
}

export const BaseTopNavigationItemIcon = React.forwardRef(function BaseTopNavigationItemIcon(
  props: BaseTopNavigationItemIconProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { children, ...restProps } = props;
  return (
    <div
      css={css`
        display: inline-flex;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 6px;
        width: 44px;
        height: 44px;
      `}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  );
});
