import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';

export const baseButtonCss = css`
  transition: background-color 0.2s ease-out;
  @media (hover: hover) {
    &:hover {
      background-color: ${vars.color.blackOpacity3};
    }
  }
`;
