import React from 'react';
import { IconHomePdpBold, IconMenuBold, IconSearchBold, IconShoppingbagBold } from '@croquiscom-pvt/zds';
import { useRouter } from 'next/router';

import { BaseTopNavigation } from '../../base';
import { ZDSComponentCommonProps } from '../../utils/types';
import { CartItemsCountBadge } from '../common/CartItemsCountBadge';
import { baseButtonCss } from '../common/styles';
import { useZigzagTopNavigationContext } from '../ZigzagTopNavigationProvider';

declare module 'react' {
  interface CSSProperties {
    '--badge-background-color'?: string;
  }
}

export interface ZigzagTopNavigationItemIconCategoryIconProps extends ZDSComponentCommonProps {
  /**
   * @default '/categories'
   */
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ZigzagTopNavigationItemIconCategoryIcon = React.forwardRef(
  function ZigzagTopNavigationItemIconCategoryIcon(
    props: ZigzagTopNavigationItemIconCategoryIconProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) {
    const { href = '/categories', onClick, ...restProps } = props;

    const context = useZigzagTopNavigationContext();

    const icon = context?.ItemIcon?.CategoryIcon ?? <IconMenuBold size={32} />;

    return (
      <BaseAnchor ref={ref} aria-label='카테고리' href={href} onClick={onClick} {...restProps}>
        <BaseTopNavigation.ItemIcon>{icon}</BaseTopNavigation.ItemIcon>
      </BaseAnchor>
    );
  },
);

export interface ZigzagTopNavigationItemIconSearchIconProps extends ZDSComponentCommonProps {
  /**
   * @default '/search'
   */
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ZigzagTopNavigationItemIconSearchIcon = React.forwardRef(function ZigzagTopNavigationItemIconSearchIcon(
  props: ZigzagTopNavigationItemIconSearchIconProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const { href = '/search', onClick, ...restProps } = props;
  const context = useZigzagTopNavigationContext();

  const icon = context?.ItemIcon?.SearchIcon ?? <IconSearchBold size={32} />;

  return (
    <BaseAnchor ref={ref} aria-label='검색' href={href} onClick={onClick} {...restProps}>
      <BaseTopNavigation.ItemIcon>{icon}</BaseTopNavigation.ItemIcon>
    </BaseAnchor>
  );
});

export interface ZigzagTopNavigationItemIconCartIconProps extends ZDSComponentCommonProps {
  /**
   * @default '/cart'
   */
  href?: string;
  /**
   * @description 배지의 배경색을 지정합니다. 셀렉티드관과 같은 강조(Accent Primary) 색상이 변경되는 경우 사용합니다.
   * @example '#FF4C34'
   */
  badgeBackgroundColor?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ZigzagTopNavigationItemIconCartIcon = React.forwardRef(function ZigzagTopNavigationItemIconCartIcon(
  props: ZigzagTopNavigationItemIconCartIconProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const { href = '/cart', badgeBackgroundColor, onClick, ...restProps } = props;
  const context = useZigzagTopNavigationContext();

  const icon = context?.ItemIcon?.CartIcon ?? <IconShoppingbagBold size={32} />;

  const badgeStyle = badgeBackgroundColor ? { '--badge-background-color': badgeBackgroundColor } : undefined;

  return (
    <BaseAnchor ref={ref} aria-label='장바구니' href={href} onClick={onClick} {...restProps}>
      <BaseTopNavigation.ItemIcon>
        <CartItemsCountBadge style={badgeStyle}>{icon}</CartItemsCountBadge>
      </BaseTopNavigation.ItemIcon>
    </BaseAnchor>
  );
});

interface ZigzagTopNavigationItemIconHomeIconProps extends ZDSComponentCommonProps {
  /**
   * @default '/'
   */
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ZigzagTopNavigationItemIconHomeIcon = React.forwardRef(function ZigzagTopNavigationItemIconHomeIcon(
  props: ZigzagTopNavigationItemIconHomeIconProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const { href = '/', onClick, ...restProps } = props;
  const context = useZigzagTopNavigationContext();

  const icon = context?.ItemIcon?.HomeIcon ?? <IconHomePdpBold size={32} />;

  return (
    <BaseAnchor ref={ref} aria-label='홈' href={href} onClick={onClick} {...restProps}>
      <BaseTopNavigation.ItemIcon>{icon}</BaseTopNavigation.ItemIcon>
    </BaseAnchor>
  );
});

const BaseAnchor = React.forwardRef(function BaseAnchor(
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const router = useRouter();
  const { href, children, onClick, ...restProps } = props;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // 외부 링크로 이동하는 경우 router에 의존하지 않고 네이티브 href와 onClick 함수만 처리합니다.
    if (href && href.startsWith('http')) {
      onClick?.(e);
      return;
    }

    e.preventDefault();
    onClick?.(e);

    if (href) {
      router.push(href);
    }
  };
  return (
    <a ref={ref} css={baseButtonCss} href={href} onClick={handleClick} {...restProps}>
      {children}
    </a>
  );
});
