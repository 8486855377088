import { useQuery } from 'react-query';
import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import { RequestContext } from '@common/api-request';

import { getWebBannerList } from '../graphql/banner.2';

interface BannerQueryParams {
  context?: RequestContext;
  external_page_id?: string;
}

/** @public */
export const queryKeys = createQueryKeyStore({
  banner: {
    list: null,
  },
});

/** @public */
export const bannerQueryOptions = (
  context?: BannerQueryParams['context'],
  { external_page_id = '' }: { external_page_id?: BannerQueryParams['external_page_id'] } = {},
) => ({
  queryFn: async () => {
    const {
      data: { web_banner_list },
    } = await getWebBannerList({ type: 'new-4', external_page_id }, { context });
    return web_banner_list;
  },
  queryKey: [queryKeys.banner.list],
});

export function useBannerQuery() {
  return useQuery(bannerQueryOptions());
}

export const useGetBanner = ({
  external_page_id = '',
}: { external_page_id?: BannerQueryParams['external_page_id'] } = {}) => {
  const isNaverBot =
    typeof window !== 'undefined' && (/(Yeti)/i.test(navigator.userAgent) || /(Naverbot)/i.test(navigator.userAgent));

  return useQuery(['main_banner_list'], () => getWebBannerList({ type: 'new-4', external_page_id }), {
    select: ({ data }) => data.web_banner_list?.item_list?.filter(({ image_url }) => image_url),
    enabled: !isNaverBot,
  });
};
