import React from 'react';
import { Text } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';

import type { ZDSComponentCommonProps } from '../utils/types';

const itemBadgeCss = css`
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  pointer-events: none;
  user-select: none;
  --badge-background-color: ${vars.color.accentPinkPrimary};
  --badge-text-color: ${vars.color.staticWhite};
`;

const itemBadgeBadgeCss = css`
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  border-radius: ${vars.cornerRadius.full};
  background: var(--badge-background-color);
  color: var(--badge-text-color);
`;

const itemBadgeBadgeTypeNumberCss = css`
  min-width: 15px;
  height: 15px;
  padding: 1px 4px 2px;
  left: 100%;
  transform: translateX(-15px);
`;

const itemBadgeBadgeTypeDotCss = css`
  width: 5px;
  height: 5px;
  right: 0;
`;

export interface BaseTopNavigationItemBadgeProps extends ZDSComponentCommonProps {
  /**
   * @default number
   */
  badgeType?: 'dot' | 'number';
  count?: number;
  children: React.ReactNode;
}

export const BaseTopNavigationItemBadge = React.forwardRef(function BaseTopNavigationItemBadge(
  props: BaseTopNavigationItemBadgeProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { className, style, badgeType = 'number', count, children } = props;
  const hasCount = typeof count !== 'undefined';
  return (
    <div css={itemBadgeCss} ref={ref} className={className} style={style}>
      {children}

      {hasCount && count > 0 && (
        <Text
          variant='Caption_10_Bold'
          css={[
            itemBadgeBadgeCss,
            badgeType === 'number' && itemBadgeBadgeTypeNumberCss,
            badgeType === 'dot' && itemBadgeBadgeTypeDotCss,
          ]}
          aria-label='장바구니에 담긴 상품 수'
        >
          {badgeType === 'number' ? (count > 99 ? '99+' : count) : null}
        </Text>
      )}
    </div>
  );
});
