import React from 'react';
import { useQuery } from 'react-query';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cartItemsCountQueryOption } from '@widgets/cart-items-count';

import { BaseTopNavigation } from '../../base';
import { ZDSComponentCommonProps } from '../../utils/types';

interface CartItemsCountBadgeProps extends ZDSComponentCommonProps {
  children: React.ReactNode;
}

export const CartItemsCountBadge = (props: CartItemsCountBadgeProps) => {
  const { data } = useQuery(cartItemsCountQueryOption());

  return <BaseTopNavigation.ItemBadge count={data} {...props} />;
};
